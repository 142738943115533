document.addEventListener('turbo:load', function() {
  if (!window.location.href.includes('data') ) {
    let targetDiv = document.getElementById('target-div');
    let otherDivs = document.querySelectorAll('.other-div');

    let targetWidth = targetDiv.offsetWidth + 'px';
    console.log(targetWidth);
    console.log('marki1');

    otherDivs.forEach(function(div) {
      div.style.width = targetWidth;
      div.style.display = "flex";
      div.style.justifyContent = "center";
      div.style.alignItems = 'center';
      console.log(targetWidth);
    });
  }  
})